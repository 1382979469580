import { Link } from 'gatsby';
import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-4 px-3 mt-28 mb-8 text-gray-800">
    <div className="flex -mx-3">
      {/* <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">Ridiculus mus mauris vitae ultricies leo integer malesuada nunc.</p>
      </div> */}
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Legal</h2>
        <ul className="mt-4 leading-loose">
          {/* <li>
            <Link className="menu" to="/terms">Terms &amp; Conditions</Link>
          </li> */}
          <li>
            <Link className="menu" to="/privacy">Privacy Policy</Link>
          </li>
        </ul>
      </div>
      {/* <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Connect</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a className="menu" href="https://facebook.com/floofjournal"
                target={"_blank"} rel="noreferrer" title={"Floof Journal on Facebook"}>
                Facebook
            </a>
          </li>
          <li>
            <a className="menu" href="https://twitter.com/floofjournal"
              target={"_blank"} rel="noreferrer" title={"Floof Journal on Twitter"}>
                  Twitter
            </a>
          </li>
          <li>
            <a className="menu" href="https://instagram.com/realfloofjournal" 
                target={"_blank"} rel="noreferrer" title={"Floof Journal on Instagram"}>
                    Instagram
            </a>
          </li>
        </ul>
      </div> */}

      <div>
      </div>
    </div>

    <br />
    <br />
    <p className="mt-2 font-light leading-relaxed" style={{ fontSize:"0.5rem"}}>
      Floof Journal<span style={{ fontSize:"0.3rem"}} className="fsup">TM</span> is a trademark of Malaud LLC.
    </p>
    <p className="mt-2 font-light leading-relaxed" style={{ fontSize:"0.5rem"}}>
      &#169; {new Date().getFullYear()} {` `} Malaud LLC. 
      All rights reserved.
    </p>

  </footer>
);

export default Footer;
